import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import cc from 'classcat'

import { removeTrailingSlash } from '../utils/helpers'

import { Consumer, Icon, Portal } from './Utilities'
import Social from './Social'

import posed from 'react-pose'
const Wrapper = posed.div({
  open: {
    x: '0%',
    staggerChildren: 20,
    delayChildren: 200
  },
  closed: {
    x: '100%',
    afterChildren: true,
    staggerChildren: 10,
    staggerDirection: -1
  }
})

const Item = posed.div({
  open: {
    opacity: 1,
    y: '0px'
  },
  closed: {
    opacity: 0,
    y: '-10px'
  }
})

const Close = posed.button({
  open: {
    rotate: '180deg'
  },
  closed: {
    rotate: '0deg'
  }
})

export default class SideNav extends Component {
  render() {
    const { checkIfActive } = this.props
    return (
      <Consumer>
        {ctx => (
          <Portal>
            <div
              className={cc({
                theme: true,
                'theme--no-padding': true,
                'theme--dark': ctx.state.darkTheme
              })}
            >
              <div
                className={cc({
                  SideNav: true,
                  'SideNav--is-open': ctx.state.showMenu
                })}
              >
                <div
                  className="SideNav__backdrop"
                  onClick={ctx.actions.toggleMenu}
                />
                <Wrapper
                  pose={ctx.state.showMenu ? 'open' : 'closed'}
                  className="SideNav__wrapper"
                >
                  <Close
                    aria-label="close button"
                    className="SideNav__close"
                    tabIndex="-1"
                    onClick={ctx.actions.toggleMenu}
                  >
                    <Icon
                      name="close"
                      color={ctx.state.darkTheme ? 'black' : 'white'}
                    />
                  </Close>
                  <nav className="SideNav__nav hidden-lg">
                    {ctx.state.menus.secondary &&
                      ctx.state.menus.secondary.items.map(item => (
                        <Item key={item.ID}>
                          <NavLink
                            to={removeTrailingSlash(item.url)}
                            className="SideNav__item"
                            activeClassName="SideNav__item--is-active"
                            isActive={(match, location) =>
                              checkIfActive(item, match, location)
                            }
                            onClick={ctx.actions.toggleMenu}
                            tabIndex="-1"
                          >
                            {item.title}
                          </NavLink>
                        </Item>
                      ))}
                  </nav>
                  <nav className="SideNav__nav hidden-md">
                    {ctx.state.menus.primaryMobile &&
                      ctx.state.menus.primaryMobile.items.map(item => (
                        <Item key={item.ID}>
                          <NavLink
                            to={removeTrailingSlash(item.url)}
                            className="SideNav__item"
                            activeClassName="SideNav__item--is-active"
                            isActive={(match, location) =>
                              checkIfActive(item, match, location)
                            }
                            onClick={ctx.actions.toggleMenu}
                            tabIndex="-1"
                          >
                            {item.title}
                          </NavLink>
                        </Item>
                      ))}
                  </nav>
                  <Item>
                    <Social />
                  </Item>
                </Wrapper>
              </div>
            </div>
          </Portal>
        )}
      </Consumer>
    )
  }
}
