import React from 'react'
import { Link } from 'react-router-dom'

import { removeTrailingSlash } from '../../utils/helpers'

const LinkHandler = ({ url, children, ...props }) => {
  return url[0] === '/' || url.includes('dept.no') ? (
    <Link to={removeTrailingSlash(url)} {...props}>
      {children}
    </Link>
  ) : (
    <a href={url} {...props} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

export default LinkHandler
