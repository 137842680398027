import React, { Component, Fragment } from 'react'

import { getPageBySlug, getFrontPage } from '../utils/wp'

import { Loader, VisuallyHidden, Html } from '../components/Utilities'
import PageWrapper from '../components/PageWrapper'
import Creatives from '../components/Creatives'
import Contacts from '../components/Contacts'
import Projects from '../components/Projects'
import ModuleRenderer from '../components/ModuleRenderer'

import NoMatchPage from '../containers/NoMatchPage'
import Hero from '../components/Hero'

export default class Page extends Component {
  state = {
    page: {},
    slug: '',
    loading: true,
  }
  componentDidMount = () => {
    const slug = this.props.match.params.slug
    this.getPage(slug)
  }
  componentDidUpdate = (prevProps) => {
    const slug = this.props.match.params.slug
    if (slug !== prevProps.match.params.slug) {
      this.setState({ loading: true })
      window.scroll(0, 0)
      this.getPage(slug)
    }
  }
  getPage = (slug) => {
    if (!slug && this.props.match.path === '/') {
      getFrontPage().then((page) => this.setState({ page, loading: false }))
    } else {
      getPageBySlug(slug).then((page) => {
        this.setState({
          page,
          slug,
          loading: false,
        })
      })
    }
  }
  render() {
    const { page, loading } = this.state
    if (loading) {
      return <Loader />
    } else {
      if (page) {
        switch (page.template) {
          case 'page-templates/creatives.php':
            return (
              <Fragment>
                {page.id && (
                  <PageWrapper location={this.props.location} page={page}>
                    {page.template !== 'page-templates/homepage.php' && (
                      <Hero page={page} />
                    )}
                    <Creatives />
                  </PageWrapper>
                )}
              </Fragment>
            )
          case 'page-templates/contact.php':
            return (
              <Fragment>
                {page.id && (
                  <PageWrapper location={this.props.location} page={page}>
                    {page.template !== 'page-templates/homepage.php' && (
                      <Hero page={page} />
                    )}
                    <Contacts />
                    {page.acf.sections &&
                      page.acf.sections.map((section, i) => (
                        <ModuleRenderer
                          key={`${page.id}-${section.acf_fc_layout}-${i}`}
                          section={section}
                        />
                      ))}
                  </PageWrapper>
                )}
              </Fragment>
            )
          case 'page-templates/projects.php':
            return (
              <Fragment>
                {page.id && (
                  <PageWrapper location={this.props.location} page={page} dark>
                    <VisuallyHidden>
                      <h1>
                        <Html content={page.title.rendered} />
                      </h1>
                    </VisuallyHidden>
                    <Projects />
                  </PageWrapper>
                )}
              </Fragment>
            )
          default:
            return (
              <Fragment>
                {page.id && (
                  <PageWrapper location={this.props.location} page={page}>
                    <VisuallyHidden>
                      <h1>
                        <Html content={page.title.rendered} />
                      </h1>
                    </VisuallyHidden>
                    {page.template !== 'page-templates/homepage.php' && (
                      <Hero page={page} />
                    )}
                    {page.acf.sections &&
                      page.acf.sections.map((section, i) => (
                        <ModuleRenderer
                          key={`${page.id}-${section.acf_fc_layout}-${i}`}
                          section={section}
                        />
                      ))}
                  </PageWrapper>
                )}
              </Fragment>
            )
        }
      } else {
        return <NoMatchPage />
      }
    }
  }
}
