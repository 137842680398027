import { Component } from 'react'
import throttle from 'lodash/throttle'

export default class MatchMedia extends Component {
  state = {
    isMatch: window.matchMedia(`(${this.props.query})`).matches
  }
  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize)
    this.setState({
      isMatch: window.matchMedia(`(${this.props.query})`).matches
    })
  }
  handleResize = throttle(e => {
    this.setState({
      isMatch: window.matchMedia(`(${this.props.query})`).matches
    })
  }, 500)
  render() {
    return this.props.children(this.state.isMatch)
  }
}
