import React, { Component } from 'react'

import withContext from '../HOC/withContext'
import { SEO, ScrollToTop } from './Utilities'

class PageWrapper extends Component {
  componentDidMount = () => {
    if (this.props.dark) {
      this.props.ctx.actions.setDarkTheme(true)
    } else {
      this.props.ctx.actions.setDarkTheme(false)
    }
  }
  render() {
    const { page } = this.props
    return (
      <div className="page">
        <ScrollToTop />
        {page && <SEO page={page} />}
        {this.props.children}
      </div>
    )
  }
}

export default withContext()(PageWrapper)
