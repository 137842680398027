import React, { Component, createContext } from 'react'

import { getMenuBySlug, getOptions } from '../../utils/wp'

const MyContext = createContext()

export class Provider extends Component {
  state = {
    showMenu: false,
    darkTheme: false,
    menus: {},
    options: {},
    loading: true,
  }
  componentDidMount = () => {
    Promise.all([
      getMenuBySlug('primary'),
      getMenuBySlug('secondary'),
      getMenuBySlug('third'),
      getOptions(),
    ]).then((res) => {
      const [primary, secondary, primaryMobile, options] = res
      this.setState({
        menus: {
          primary,
          secondary,
          primaryMobile,
        },
        options,
        loading: false,
      })
    })
  }
  toggleMenu = () => {
    this.setState(
      (prevState) => ({ showMenu: !prevState.showMenu }),
      () => this.noScroll()
    )
  }
  setDarkTheme = (bool) => {
    this.setState({ darkTheme: bool })
  }
  noScroll = () => {
    document.querySelector('body').style.cssText = this.state.showMenu
      ? 'overflow: hidden; height: 100vh'
      : ''
    document.querySelector('html').style.cssText = this.state.showMenu
      ? 'overflow: hidden; height: 100vh'
      : ''
  }
  render() {
    return (
      <MyContext.Provider
        value={{
          state: this.state,
          actions: {
            toggleMenu: this.toggleMenu,
            setDarkTheme: this.setDarkTheme,
          },
        }}
      >
        {this.props.children}
      </MyContext.Provider>
    )
  }
}
export const Consumer = MyContext.Consumer
