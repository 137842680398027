import 'lazysizes/plugins/respimg/ls.respimg'
import 'lazysizes/plugins/bgset/ls.bgset'
import 'lazysizes'

window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.customMedia = {
  '--sm': '(max-width: 768px)',
  '--md': '(max-width: 1024px)',
  '--lg': '(max-width: 1400px)'
}
