import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

import { keygen } from '../utils/helpers'

export default class NameList extends Component {
  render() {
    const { people, title, inProjects } = this.props
    return (
      people &&
      people.map((person, i) => (
        <Fragment key={keygen(title)}>
          {inProjects ? (
            <button
              className="text-link"
              onClick={() => {
                this.props.resetFilter()
                this.props.handleFilter({ person: person.post_name })
              }}
            >
              {person.post_title}
            </button>
          ) : (
            <Link className="text-link" to={`/work?person=${person.post_name}`}>
              {person.post_title}
            </Link>
          )}
          {i < people.length - 2 && `, `}
          {i === people.length - 2 && ` and `}
        </Fragment>
      ))
    )
  }
}
