import React, { Component } from 'react'

import { keygen } from '../utils/helpers'

import { Consumer } from './Utilities'

export default class Social extends Component {
  render() {
    return (
      <Consumer>
        {ctx => (
          <div className="Social">
            {ctx.state.options.some &&
              Object.keys(ctx.state.options.some).map(channel => (
                <a
                  key={keygen('some')}
                  href={ctx.state.options.some[channel]}
                  className="Social__item"
                  tabIndex="-1"
                >
                  {/* <Icon
                    name={channel}
                    color={ctx.state.darkTheme ? 'black' : 'white'}
                  /> */}
                  {channel}
                </a>
              ))}
          </div>
        )}
      </Consumer>
    )
  }
}
