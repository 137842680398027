import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router'

import { removeHtmlTags } from '../../utils/helpers'

class SEO extends Component {
  getCanonicalUrl = (data) => {
    const url =
      data?.yoast_seo?.canonical ||
      `https://dept.no${this.props.location.pathname}`
    return url
  }
  getDescription = (data, key = '') => {
    const defaultDescription =
      'At Dept. we make things come alive: be it animations, film, installations, photos illustrations. We push the envelope and get our hands dirty.'
    if (data.yoast_seo && data.yoast_seo[key]) {
      return data.yoast_seo[key] || defaultDescription
    }
    if (data.yoast_seo && data.yoast_seo.description) {
      return data.yoast_seo.description || defaultDescription
    }
    return removeHtmlTags(data.excerpt.rendered) || defaultDescription
  }

  getTitle = (data, key = '') => {
    if (data.yoast_seo && data.yoast_seo[key]) {
      return data.yoast_seo[key]
    }
    if (data.yoast_seo && data.yoast_seo.title) {
      return data.yoast_seo.title
    }
    return data.title.rendered
  }

  getImage = (data, key = '') => {
    if (data.yoast_seo && data.yoast_seo[key]) {
      return data.yoast_seo[key]
    }
    if (data.featured_image) {
      return data.featured_image.sizes.large
    }
    return false
  }
  render() {
    const data = this.props.page
    let siteName, twitterName, title, description, meta
    if (data.id) {
      siteName = '____Dept.'
      twitterName = ''
      title = this.getTitle(data, 'wp_title')
      description = this.getDescription(data, 'description')
      meta = [
        // Google / Search engines in general
        { name: 'description', content: description },
        { itemprop: 'name', content: siteName },
        { itemprop: 'description', content: description },
        { itemprop: 'image', content: this.getImage(data) },

        // Facebook
        { property: 'og:url', content: this.getCanonicalUrl(data) },
        { property: 'og:title', content: this.getTitle(data, 'og_title') },
        {
          property: 'og:description',
          content: this.getDescription(data, 'og_description'),
        },
        { property: 'og:image', content: this.getImage(data, 'og_image') },
        { property: 'og:image:width', content: 1800 },
        { property: 'og:image:height', content: 945 },

        // Twitter
        { name: 'twitter:card', content: 'summary' },
        {
          name: 'twitter:title',
          content: this.getTitle(data, 'twitter_title'),
        },
        {
          name: 'twitter:description',
          content: this.getDescription(data, 'twitter_description'),
        },
        { name: 'twitter:site', content: twitterName },
        { name: 'twitter:creator', content: twitterName },
        {
          name: 'twitter:image',
          content: this.getImage(data, 'twitter_image'),
        },
      ]
    }
    return (
      <Helmet title={title} meta={meta}>
        <link rel="canonical" href={this.getCanonicalUrl(data)} />
      </Helmet>
    )
  }
}

export default withRouter(SEO)
