// import React, { Component, createRef, Fragment } from 'react'
// import Vimeo from '@u-wave/react-vimeo'

// export default class VimeoWrapper extends Component {
//   video = createRef()
//   state = {
//     loaded: false
//   }

//   makeEmbedsResponsive = () => {
//     const el = this.video.current.container
//     if (el) {
//       let iframe = el.getElementsByTagName('iframe')
//       if (iframe) {
//         iframe = iframe[0]
//         const w = parseFloat(iframe.getAttribute('width'))
//         const h = parseFloat(iframe.getAttribute('height'))
//         if (h && w) {
//           const pusher = document.createElement('div')
//           pusher.className = 'embed__pusher'
//           pusher.style.paddingTop = (h / w) * 100 + '%'
//           el.appendChild(pusher)
//         }
//       }
//     }
//   }
//   handleLoad = () => {
//     this.makeEmbedsResponsive()
//     this.setState({
//       loaded: true
//     })
//   }
//   render() {
//     const { video, className } = this.props
//     return (
//       <Fragment>
//         <Vimeo
//           ref={this.video}
//           video={video}
//           width="100%"
//           className={`${className} embed`}
//           showTitle={false}
//           showByline={false}
//           onLoaded={this.handleLoad}
//         />
//         {!this.state.loaded && (
//           <div className="aspect aspect-sm--landscape">
//             <div className="LineLoader" />
//           </div>
//         )}
//       </Fragment>
//     )
//   }
// }

import React, { Component, createRef } from 'react'
import Vimeo from '@u-wave/react-vimeo'
import cc from 'classcat'
import VisibilitySensor from 'react-visibility-sensor'

import Icon from './Icon'
import MatchMedia from './MatchMedia'
import PageVisibilitySensor from './PageVisibilitySensor'

export default class VimeoWrapper extends Component {
  video = createRef()
  wrapper = createRef()
  state = {
    loaded: true,
    muted: this.props.autoplay,
  }

  makeEmbedsResponsive = () => {
    const el = this.video.current.container
    if (el) {
      let iframe = el.getElementsByTagName('iframe')
      if (iframe) {
        iframe = iframe[0]
        const w = parseFloat(iframe.getAttribute('width'))
        const h = parseFloat(iframe.getAttribute('height'))
        if (h && w) {
          const pusher = document.createElement('div')
          pusher.className = 'embed__pusher'
          pusher.style.paddingTop = (h / w) * 100 + '%'
          el.appendChild(pusher)
        }
      }
    }
  }
  handleLoad = () => {
    this.makeEmbedsResponsive()
  }
  toggleMute = () => this.setState((prevState) => ({ muted: !prevState.muted }))
  render() {
    const { video, className, autoplay } = this.props
    const { loaded, muted } = this.state
    return (
      <MatchMedia query="min-width: 768px">
        {(isMatch) => (
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <PageVisibilitySensor>
                {({ isPageVisible }) => (
                  <div
                    ref={this.wrapper}
                    className={cc({
                      VimeoWrapper: true,
                      'VimeoWrapper--hidden': !loaded,
                      // 'VimeoWrapper--autoplay': autoplay && isMatch
                    })}
                    style={{ paddingTop: '56.25%' }}
                  >
                    <Vimeo
                      ref={this.video}
                      video={video}
                      width="100%"
                      className={cc({
                        embed: true,
                        [className]: className,
                      })}
                      paused={autoplay && (!isVisible || !isPageVisible)}
                      loop={autoplay}
                      muted={muted}
                      showTitle={false}
                      showByline={false}
                      showPortrait={false}
                      // background={autoplay && isMatch}
                      autoplay={autoplay && isVisible && isPageVisible}
                      onLoaded={this.handleLoad}
                      onPlay={() =>
                        this.setState({
                          loaded: true,
                        })
                      }
                    />
                    {autoplay && isMatch && (
                      <button
                        className="VimeoWrapper__mute"
                        onClick={this.toggleMute}
                      >
                        {muted ? (
                          <Icon name="sound-off" color="white" />
                        ) : (
                          <Icon name="sound-on" color="white" />
                        )}
                      </button>
                    )}
                    {this.props.children}
                  </div>
                )}
              </PageVisibilitySensor>
            )}
          </VisibilitySensor>
        )}
      </MatchMedia>
    )
  }
}
