import { nanoid } from 'nanoid'

export function getHtmlSymbol(string) {
  const parser = new DOMParser()
  return parser.parseFromString(`<!doctype html><body>${string}`, 'text/html')
    .body.textContent
}

export function getQueryStringParams(query) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=')
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : ''
          return params
        }, {})
    : {}
}

export function keygen(slug) {
  return `${slug}-${nanoid()}`
}

export function getReadTime(content) {
  const images = content.acf.modules
    ? content.acf.modules.filter((module) => module.acf_fc_layout === 'image')
        .length
    : 0
  const text = content.acf.modules
    .filter((module) => module.acf_fc_layout === 'text')
    .reduce((string, module) => {
      string += module.content
      return string
    }, '')
  return parseInt(text.split(' ').length / 275 + images * 0.12, 10)
}

export function pickRandom(list, amount) {
  const arr = new Array(amount).fill('x')
  const newLogos = arr.map(() => list[Math.floor(Math.random() * list.length)])
  return newLogos
}

export function removeTrailingSlash(url) {
  return url[url.length - 1] === '/' ? url.slice(0, -1) : url
}

export const httpsRedirect = () => {
  if (
    process.env.NODE_ENV === 'production' &&
    window.location.protocol !== 'https:'
  )
    window.location.replace('https://' + window.location.href.split('//')[1])
}

// WORDPRESS

export const acfImageToSrcArray = (sizes, maxWidth = 99999) => {
  // Remove sizes if multiple of same size
  /**
   * TODO: Definere de bildestørrelsene man ønsker å bruke her
   * feks sizes = ['small','medium','large','xlarge']
   *
   */
  const sizesFiltered = Object.keys(sizes).reduce((build, key) => {
    if (key.indexOf('-width') !== -1) {
      const width = sizes[key]
      if (width <= maxWidth) {
        const srcKey = key.replace('-width', '')
        build[width] = sizes[srcKey]
      }
    }
    return build
  }, {})

  return sizesFiltered
}

export const acfImageToSrcset = (sizes, maxWidth = 99999) => {
  const sizesFiltered = acfImageToSrcArray(sizes, maxWidth)
  return srcArrayToSrcset(sizesFiltered)
}

export const srcArrayToSrcset = (sizes) => {
  const srcsetArray = Object.keys(sizes).map((size) => {
    return `${sizes[size]} ${size}w`
  })
  return srcsetArray.join(', ')
}

export const removeHtmlTags = (string) => string.replace(/<(?:.|\n)*?>/gm, '')
