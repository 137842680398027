import React, { Component } from 'react'
import { getPeople } from '../utils/wp'
import { AspectImage, Html } from './Utilities'

export default class Contacts extends Component {
  state = {
    people: []
  }
  componentDidMount = () => {
    getPeople().then(people =>
      this.setState({
        people: people.filter(person => person.acf.show_on_contact_page)
      })
    )
  }

  render() {
    const { people } = this.state
    return (
      <ul className="Contacts">
        {people &&
          people.map(person => (
            <li className="Contacts__item" key={person.id}>
              <div className="Contacts__info">
                <div className="Contacts__text">
                  {person.title && (
                    <h3 className="Contacts__name">
                      <Html content={person.title.rendered} />
                    </h3>
                  )}
                  {person.acf.role && (
                    <p className="Contacts__role">{person.acf.role}</p>
                  )}
                </div>
                <div className="Contacts__media">
                  {person.featured_image ? (
                    <AspectImage
                      image={person.featured_image}
                      className="Contacts__image aspect-sm--square"
                    />
                  ) : (
                    <div
                      role="img"
                      title={`placeholder image for ${person.title.rendered}`}
                      className="Contacts__image Contacts__image--placeholder aspect lazyload aspect-sm--landscape"
                    />
                  )}
                </div>
              </div>
              <div className="Contacts__meta">
                {person.acf.email && (
                  <a
                    className="Contacts__email Contacts__link"
                    href={`mailto:${person.acf.email}`}
                  >
                    {person.acf.email}
                  </a>
                )}
                {person.acf.phone_number && (
                  <a
                    className="Contacts__phone Contacts__link"
                    href={`tel:${person.acf.phone_number}`}
                  >
                    {person.acf.phone_number}
                  </a>
                )}
              </div>
            </li>
          ))}
      </ul>
    )
  }
}
