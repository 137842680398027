import React, { Component } from 'react'
import cc from 'classcat'

import { Html } from './Utilities'

export default class Hero extends Component {
  state = {
    show: false
  }
  componentDidMount = () => {
    this.timeout = setTimeout(() => {
      this.setState({
        show: true
      })
    }, 400)
  }
  componentWillUnmount = () => {
    clearTimeout(this.timeout)
  }
  render() {
    const { page } = this.props
    const { show } = this.state
    return (
      <div className={cc({ Hero: true, 'Hero--show': show })}>
        {page.title && (
          <h1
            className={cc({
              Hero__title: true,
              'Hero__title--hide': page.acf.hide_title
            })}
          >
            <Html content={page.title.rendered} />
          </h1>
        )}
        {page.content.rendered && (
          <div className="Hero__desc">
            <Html content={page.content.rendered} />
          </div>
        )}
      </div>
    )
  }
}
