import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { getPeople } from '../utils/wp'
import { AspectImage, Html } from './Utilities'

export default class Creatives extends Component {
  state = {
    people: []
  }
  componentDidMount = () => {
    getPeople().then(people =>
      this.setState({
        people: people.filter(person => !person.acf.show_on_contact_page)
      })
    )
  }

  render() {
    const { people } = this.state
    return (
      <div className="Creatives">
        {people &&
          people.map(person => (
            <div key={person.id} className="Creatives__item">
              {person.featured_image ? (
                <AspectImage
                  image={person.featured_image}
                  className="Creatives__image aspect-sm--landscape"
                />
              ) : (
                <div
                  role="img"
                  title={`placeholder image for ${person.title.rendered}`}
                  className="Creatives__image Creatives__image--placeholder aspect lazyload aspect-sm--landscape"
                />
              )}
              <h3 className="Creatives__title">
                <Html content={person.title.rendered} />
              </h3>
              {person.acf.role && (
                <p className="Creatives__meta">{person.acf.role}</p>
              )}
              {person.acf.email && (
                <a
                  className="Creatives__email Creatives__link"
                  href={`mailto:${person.acf.email}`}
                >
                  {person.acf.email}
                </a>
              )}
              {person.acf.phone_number && (
                <a
                  className="Creatives__phone Creatives__link"
                  href={`tel:${person.acf.phone_number}`}
                >
                  {person.acf.phone_number}
                </a>
              )}
              {person.content.rendered && (
                <div className="Creatives__bio">
                  <Html content={person.content.rendered} />
                </div>
              )}
              <p className="Creatives__link">
                <Link className="text-link" to={`/work?person=${person.slug}`}>
                  See all projects
                </Link>
              </p>
            </div>
          ))}
      </div>
    )
  }
}
