import React, { Component } from 'react'

export default class VisuallyHidden extends Component {
  render() {
    const style = {
      position: 'absolute',
      overflow: 'hidden',
      clip: 'rect(0 0 0 0)',
      height: '1px',
      width: '1px',
      margin: '-1px',
      padding: '0',
      border: '0'
    }
    return (
      <div className="VisuallyHidden" style={style}>
        {this.props.children}
      </div>
    )
  }
}
