import React, { Component } from 'react'

import ContentRenderer from './ContentRenderer'
import { LinkHandlerButton } from './Utilities'
import Carousel from './Carousel'

export default class ModuleRenderer extends Component {
  render() {
    const { section } = this.props
    switch (section.acf_fc_layout) {
      case 'full':
        return (
          <div className="Full">
            <ContentRenderer data={section} />
          </div>
        )
      case 'split':
        return (
          <div className="Split">
            <div className="Split__left">
              <ContentRenderer data={section.left} />
            </div>
            <div className="Split__right">
              <ContentRenderer data={section.right} />
            </div>
          </div>
        )
      case 'button':
        return (
          <div className="Full">
            <div className="Full__button">
              <LinkHandlerButton
                className="button"
                url={section.button.url}
                title={section.button.title}
                withIcon
              />
            </div>
          </div>
        )
      case 'slideshow':
        return (
          <div className="Full">
            <Carousel>
              {section.sections.map((slideSection, i) => (
                <ModuleRenderer key={`section-${i}`} section={slideSection} />
              ))}
            </Carousel>
          </div>
        )
      default:
        return <h1>Fant ikke komponenten</h1>
    }
  }
}
