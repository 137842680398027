import React, { Component } from 'react'
import { Consumer } from '../components/Utilities'

const withContext = () => WrappedComponent => {
  return class DataWrapper extends Component {
    render() {
      return (
        <Consumer>
          {ctx =>
            ctx.state.options['logo'] && (
              <WrappedComponent {...this.props} {...this.state} ctx={ctx} />
            )
          }
        </Consumer>
      )
    }
  }
}

export default withContext
