import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Consumer } from './Context'

export default class StructuredData extends Component {
  render() {
    return (
      <Consumer>
        {ctx => (
          <Helmet>
            <script type="application/ld+json">
              {`{
                  "@context":"http://schema.org",
                  "@type":"LocalBusiness",
                  "image": [
                    "https://dept.netlify.com/android-chrome-512x512.png"
                  ],
                  "@id":"https://www.dept.no",
                  "name":"Dept.",
                  "address":{
                    "@type":"PostalAddress",
                    "streetAddress":"Møllergata 47B",
                    "addressLocality":"Oslo",
                    "postalCode":"0179",
                    "addressCountry":"NO"
                  },
                  "geo":{
                    "@type":"GeoCoordinates",
                    "latitude":10.750049,
                    "longitude":59.917784
                    
                  },
                  "telephone":"0047 91695073",
                  "openingHoursSpecification": [
                    {
                      "@type": "OpeningHoursSpecification",
                      "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday"
                      ],
                      "opens": "09:00",
                      "closes": "16:00"
                    }
                  ]
                }
              `}
            </script>
          </Helmet>
        )}
      </Consumer>
    )
  }
}
