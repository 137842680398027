import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import posed, { PoseGroup } from 'react-pose'
import { uniqBy } from 'lodash'

import { getProjects, getProjectCategories } from '../utils/wp'
import { getQueryStringParams } from '../utils/helpers'

import { AspectImage, Html } from './Utilities'
import NameList from './NameList'
import Filter from './Filter'

const selectText = 'By creator'

const Item = posed.div({
  init: { opacity: 0, y: -100 },
  enter: { opacity: 1, y: 0 },
  leave: { opacity: 0, y: 100 }
})

export default class Projects extends Component {
  state = {
    projects: [],
    currentProjects: [],
    categories: [],
    currentFilter: {
      category: '',
      person: selectText
    }
  }
  componentDidMount = () => {
    Promise.all([getProjects(), getProjectCategories()]).then(
      ([projects, categories]) =>
        this.setState(
          {
            projects,
            people: uniqBy(
              projects.reduce((res, project) => {
                if (project.acf.our_people[0]) {
                  res.push(...project.acf.our_people[0].person)
                }
                return res
              }, []),
              'ID'
            ),
            currentProjects: projects,
            categories
          },
          () => {
            const queries = getQueryStringParams(window.location.search)
            // Force triggering filterhandling after state is set
            if (queries['category']) {
              this.handleFilter({
                category: queries['category']
              })
            }
            if (queries['person']) {
              this.handleFilter({
                person: queries['person']
              })
            }
          }
        )
    )
    this.setState({
      search: window.location.search
    })
  }
  handleFilter = filter => {
    const key = Object.keys(filter)[0]

    if (filter[key] === null)
      return this.setState(prevState => ({
        currentProjects: this.state.projects,
        currentFilter: Object.assign(
          {},
          {
            ...prevState.currentFilter,
            [key]: filter[key]
          }
        )
      }))
    this.setState(prevState => ({
      currentFilter: Object.assign(
        {},
        {
          ...prevState.currentFilter,
          [key]: filter[key]
        }
      ),
      currentProjects: this.updateCollection(
        Object.assign(
          {},
          {
            ...prevState.currentFilter,
            [key]: filter[key]
          }
        )
      )
    }))
  }
  updateCollection = ({ category, person }) => {
    return [...this.state.projects]
      .filter(project =>
        category !== '' ? this.hasCategory(project, category) : true
      )
      .filter(project =>
        person !== selectText
          ? this.hasPerson(project.acf.our_people[0], person)
          : true
      )
  }
  hasCategory = (project, category) => {
    if (!project._embedded) return false
    const categoryString = project._embedded['wp:term'][0]
      .map(cat => cat.slug)
      .join()
    return categoryString.includes(category)
  }
  hasPerson(people, person) {
    if (people && people.person) {
      return people.person.reduce((res, current) => {
        if (current.post_name === person) {
          res = true
        }
        return res
      }, false)
    } else {
      return false
    }
  }
  resetFilter = () => {
    this.setState({
      currentFilter: { category: '', person: selectText },
      currentProjects: [...this.state.projects]
    })
  }
  componentDidUpdate = (prevProps, prevState) => {
    // console.log(window.location.search, prevState.search)
    // Handling filtering when query string updates
    if (prevState.search !== window.location.search) {
      this.setState(
        {
          search: window.location.search
        },
        () => {
          const queries = getQueryStringParams(window.location.search)
          if (queries['category']) {
            this.resetFilter()
            this.handleFilter({
              category: queries['category']
            })
          }
          if (queries['person']) {
            this.resetFilter()
            this.handleFilter({
              person: queries['person']
            })
          }
          if (!queries['person'] && !queries['category']) {
            this.resetFilter()
          }
        }
      )
    }
  }
  render() {
    const { currentProjects, categories, people, currentFilter } = this.state
    // console.log(currentProjects, currentFilter)
    return (
      <div className="Projects">
        {categories && (
          <Filter
            currentFilter={currentFilter}
            people={people}
            categories={categories}
          />
        )}
        <div className="Projects__list">
          <PoseGroup animateOnMount flipMove enterPose="enter" exitPose="leave">
            {currentProjects &&
              currentProjects.map(project => (
                <Item className="Projects__item" key={project.id}>
                  <figure className="Projects__feature">
                    {project.featured_image && project.featured_image.id && (
                      <Link to={`/project/${project.slug}`}>
                        <AspectImage
                          image={project.featured_image}
                          className="Projects__image aspect-sm--landscape"
                        />
                      </Link>
                    )}
                    <figcaption className="Projects__caption">
                      {project.title.rendered && (
                        <Html content={project.title.rendered} />
                      )}
                      {project.acf.our_people &&
                        project.acf.our_people[0] &&
                        project.acf.our_people[0].person && (
                          <>
                            <span>&nbsp;•&nbsp;</span>
                            <NameList
                              inProjects
                              title={project.title.rendered}
                              people={project.acf.our_people[0].person}
                              handleFilter={this.handleFilter}
                              resetFilter={this.resetFilter}
                            />
                          </>
                        )}
                    </figcaption>
                  </figure>
                </Item>
              ))}
          </PoseGroup>
          {this.state.projects.length > 1 && currentProjects.length === 0 && (
            <div className="Projects__no-hits">
              <h2>Sorry, we found no projects with that combination :(</h2>
              <button
                className="button Projects__button"
                onClick={this.resetFilter}
              >
                <span className="button__inner">Reset filter</span>
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}
