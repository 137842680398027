import React from 'react'

const NoMatchPage = () => {
  return (
    <div className="NoMatch">
      <h1 className="NoMatch__title">404</h1>
      <p className="NoMatch__text">
        I'm sorry, Dave. <br /> I'm afraid I can't do that.
      </p>
      <p className="NoMatch__footer">
        Page not found. <br /> Broken link.
      </p>
    </div>
  )
}

export default NoMatchPage
