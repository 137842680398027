import React, { Component } from 'react'
import cc from 'classcat'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './utils/lazysizes'
import './utils/webfonts'
import './styles/app.css'

import { Provider, Consumer } from './components/Utilities'
import Header from './components/Header'
import Footer from './components/Footer'

import Page from './containers/Page'
import ProjectPage from './containers/ProjectPage'
import NoMatchPage from './containers/NoMatchPage'

export const routes = {
  frontpage: {
    name: 'Frontpage',
    path: '/',
    component: Page,
    exact: true,
    show: true,
  },
  project: {
    name: 'Project',
    path: '/project/:slug',
    component: ProjectPage,
    exact: true,
    show: true,
  },
  page: {
    name: 'Pages',
    path: '/:slug',
    component: Page,
    exact: false,
    show: true,
  },
}

const reload = () => window.location.reload()

class App extends Component {
  render() {
    return (
      <Router>
        <Provider>
          <Consumer>
            {(ctx) => (
              <div
                className={cc({
                  theme: true,
                  'theme--dark': ctx.state.darkTheme,
                  Layout: true,
                })}
              >
                {/* <StructuredData /> */}
                <Header />
                <Switch>
                  {Object.keys(routes).map((key) => (
                    <Route
                      key={routes[key].path}
                      exact={routes[key].exact}
                      path={routes[key].path}
                      component={routes[key].component}
                    />
                  ))}
                  <Route path="/robots.txt" onEnter={reload} />
                  <Route path="/sitemap.xml" onEnter={reload} />
                  <Route component={NoMatchPage} />
                </Switch>
                <Footer />
              </div>
            )}
          </Consumer>
        </Provider>
      </Router>
    )
  }
}

export default App
