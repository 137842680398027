import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import cc from 'classcat'

import { getProjectBySlug } from '../utils/wp'
import { keygen } from '../utils/helpers'

import PageWrapper from '../components/PageWrapper'
import { VimeoWrapper, Html, Icon, Image } from '../components/Utilities'
import ModuleRenderer from '../components/ModuleRenderer'
import NameList from '../components/NameList'

export default class ProjectPage extends Component {
  state = {
    project: {},
    show: false
  }
  componentDidMount = () => {
    const slug = this.props.match.params.slug
    getProjectBySlug(slug).then(project => this.setState({ project }))

    this.timeout = setTimeout(() => {
      this.setState({
        show: true
      })
    }, 400)
  }
  componentWillUnmount = () => {
    clearTimeout(this.timeout)
  }
  // shouldComponentUpdate = (nextProps, nextState) =>
  //   this.state.project !== nextState.project ? true : false
  render() {
    const { project, show } = this.state
    return (
      <Fragment>
        {project.id && (
          <PageWrapper location={this.props.location} page={project} dark>
            <article className="Project">
              <div className={cc({ Hero: true, 'Hero--show': show })}>
                {project.title && (
                  <h1 className="Hero__title">
                    <Html content={project.title.rendered} />
                  </h1>
                )}
                {project.acf.our_people &&
                  project.acf.our_people[0] &&
                  project.acf.our_people[0].person && (
                    <div className="Hero__byline">
                      <p>
                        <span>by&nbsp;</span>
                        {project.acf.our_people[0] && (
                          <NameList
                            title={project.title.rendered}
                            people={project.acf.our_people[0].person}
                          />
                        )}
                      </p>
                    </div>
                  )}
                {project.content.rendered && (
                  <div className="Hero__desc">
                    <Html content={project.content.rendered} />
                  </div>
                )}
              </div>

              {/* List all videos */}
              {project.acf.videos &&
                project.acf.videos.map((content, i) => (
                  <figure
                    className="Project__feature"
                    key={keygen('project-video')}
                  >
                    <VimeoWrapper video={content.video} autoplay={false} />
                    {content.caption && (
                      <figcaption className="Project__caption">
                        <Html content={content.caption} />
                      </figcaption>
                    )}
                    {i === 0 && (
                      <div className="Project__credits">
                        <div className="Project__credits-internal">
                          <Html content={project.acf.credits} />
                        </div>
                        <div className="Project__credits-external">
                          <Html content={project.acf.credits_external} />
                        </div>
                      </div>
                    )}
                  </figure>
                ))}

              {/* Show featured image if no videos exist */}
              {!project.acf.videos && project.featured_image && (
                <figure className="Project__feature">
                  <Image image={project.featured_image} />
                </figure>
              )}

              {/* Page builder */}
              {project.acf.sections &&
                project.acf.sections.map((section, i) => (
                  <ModuleRenderer
                    key={`${project.id}-${section.acf_fc_layout}-${i}`}
                    section={section}
                  />
                ))}

              <Link to="/work" className="button">
                <div className="button__inner">
                  <Icon
                    className="button__icon"
                    name="arrow-left"
                    color="white"
                  />
                  Back
                </div>
              </Link>
            </article>
          </PageWrapper>
        )}
      </Fragment>
    )
  }
}
