import WPAPI from 'wpapi'
import base64 from 'base64-js'

// const env = process.env.NODE_ENV ? process.env.NODE_ENV : 'production'

// let endpoint = 'http://dept.test/api'

// if (env === 'production' || window.location.hostname !== 'localhost') {
//   endpoint = 'https://api.dept.no/api'
// }
let endpoint = 'https://api.dept.no/api'

const cache = {}

const wp = new WPAPI({
  endpoint,
  transport: {
    // Only override the transport for the GET method, in this example
    // Transport methods should take a wpreq object and a callback:
    get: function (wpreq, cb) {
      var result = cache[wpreq]
      // If a cache hit is found, return it via the same callback/promise
      // signature as the default transport method:
      if (result) {
        if (cb && typeof cb === 'function') {
          // Invoke the callback function, if one was provided
          cb(null, result)
        }
        // Return the data as a promise
        return Promise.resolve(result)
      }

      // Delegate to default transport if no cached data was found
      return WPAPI.transport.get(wpreq, cb).then(function (result) {
        cache[wpreq] = result
        return result
      })
    },
  },
})

// // Create custom routes
wp.menus = wp.registerRoute('menus/v1', '/menus/(?P<id>[a-zA-Z0-9_-]+)')
wp.options = wp.registerRoute('wp/v2/acf', '/options/')
wp.people = wp.registerRoute('wp/v2', '/people/')
wp.projects = wp.registerRoute('wp/v2', '/project/(?P<slug>[a-zA-Z0-9_-]+)')
wp.projectCategories = wp.registerRoute('wp/v2', '/project_category/')
wp.search = wp.registerRoute('relevanssi/v1', '/search/')

export const getPosts = (page = 1) => {
  return wp.posts().perPage(10).page(page).embed()
}

export const getPostBySlug = (slug) => {
  return wp
    .posts()
    .slug(slug)
    .embed()
    .then((res) => res[0])
}

export const getPageBySlug = (slug) => {
  return wp
    .pages()
    .slug(slug)
    .embed()
    .then((res) => res[0])
}

export const getFrontPage = () => {
  return wp
    .pages()
    .id(33)
    .embed()
    .then((res) => res)
}

export const getMenuBySlug = (slug) => {
  return wp.menus().id(slug)
}

export const getOptions = () => {
  return wp.options()
}

export const getPeople = () => {
  return wp.people().perPage(100)
}

export const getProjects = () => {
  return wp.projects().perPage(100).embed()
}

export const getProjectBySlug = (slug) => {
  return wp
    .projects()
    .slug(slug)
    .then((res) => res[0])
    .catch((err) => err)
}

export const getProjectCategories = () => {
  return wp.projectCategories().perPage(100).embed()
}

const wpCache = {}

export const loggedIn = () => {
  const requestUrl = endpoint + '/hey/v1/loggedin'
  const requestKey = base64.toByteArray(requestUrl)
  if (!wpCache[requestKey]) {
    wpCache[requestKey] = fetch(requestUrl, {
      method: 'get',
      credentials: 'include',
    }).then((res) => {
      return res.json()
    })
  }
  return wpCache[requestKey]
}
