import React, { useState, useEffect, useRef } from 'react'

const defaultOptions = {
  // groupCells: true,
  // freeScroll: false,
  wrapAround: false,
  // imagesLoaded: false,
  lazyLoad: true,
  resize: true,
  // contain: true,
  autoPlay: 3000,
  pageDots: false,
  prevNextButtons: false,
  adaptiveHeight: true,
}

const Carousel = ({
  children,
  options = defaultOptions,
  customButtons = true,
}) => {
  const [flkty, setFlkty] = useState(null)
  const container = useRef(null)

  // Init
  useEffect(() => {
    if (!flkty) {
      import('flickity').then((res) => {
        const flickity = res.default
        if (container && container.current) {
          setFlkty(
            new flickity(container.current, {
              ...options,
              initialIndex: 0, // set index of default slide
            })
          )
        }
      })
    }
  }, [options, children, flkty, container])

  // Prevent vertical scrolling when swiping
  useEffect(() => {
    function touchStart(e) {
      this.firstClientX = e.touches[0].clientX
      this.firstClientY = e.touches[0].clientY
    }

    function preventTouch(e) {
      const minValue = 3 // threshold

      this.clientX = e.touches[0].clientX - this.firstClientX
      this.clientY = e.touches[0].clientY - this.firstClientY

      // Vertical scrolling does not work when you start swiping horizontally.
      if (Math.abs(this.clientX) > minValue) {
        e.preventDefault()
        e.returnValue = false
        return false
      }
    }
    if (flkty) {
      // console.log('TCL: Carousel -> flkty', flkty)
      // flkty.on('dragStart', this.handleDrag)
      // flkty.on('dragEnd', this.handleDrag)
    }
    const wrapper = container && container.current
    if (wrapper) {
      wrapper.addEventListener('touchstart', touchStart, {
        passive: true,
      })
      wrapper.addEventListener('touchmove', preventTouch, {
        passive: false,
      })
    }

    return () => {
      if (container && container.current) {
        wrapper.removeEventListener('touchstart', touchStart)
        wrapper.removeEventListener('touchmove', preventTouch, {
          passive: false,
        })
      }
    }
  }, [children, options, flkty, container])

  return (
    <div className="Carousel">
      <div ref={container} id="hello">
        {children.map((child, index) => (
          <figure key={`carousel-item-${index}`} className="item">
            {React.cloneElement(child, {
              flkty,
              index,
            })}
          </figure>
        ))}
      </div>
      {customButtons && container && flkty && (
        <CustomButtons flkty={flkty} children={children} />
      )}
    </div>
  )
}

const CustomButtons = ({ flkty, children }) => {
  const [index, setIndex] = useState(flkty && flkty.selectedIndex)
  const prevButton = useRef(null)
  const nextButton = useRef(null)

  useEffect(() => {
    flkty.on('change', (i) => setIndex(i))
  }, [flkty])

  // Navigation
  useEffect(() => {
    const handlePrev = () => flkty.select(index - 1)
    const handleNext = () => flkty.select(index + 1)

    const pButton = prevButton && prevButton.current
    const nButton = nextButton && nextButton.current

    if (pButton && nButton) {
      pButton.addEventListener('click', handlePrev)
      nButton.addEventListener('click', handleNext)
    }

    return () => {
      if (pButton && nButton) {
        pButton.removeEventListener('click', handlePrev)
        nButton.removeEventListener('click', handleNext)
      }
    }
  }, [prevButton, nextButton, flkty, index])

  return (
    <nav className="Navigation" flkty={flkty} index={index} children={children}>
      {/* index: {index} */}
      <button
        ref={prevButton}
        aria-label="Previous"
        className="Navigation__button Navigation__button--prev"
      >
        Prev
      </button>
      <button
        ref={nextButton}
        aria-label="Next"
        className="Navigation__button Navigation__button--next"
      >
        Next
      </button>
    </nav>
  )
}

export default Carousel
