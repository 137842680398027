import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import posed from 'react-pose'

const hover = {
  y: 0
}
const menuItems = {
  // Animation
  1178: posed.div({
    pressable: true,
    hoverable: true,
    hover,
    init: {
      y: 0,
      rotate: 0,
      display: 'inline-block'
    },
    press: {
      rotate: 180
    }
  }),
  // Film
  1179: posed.div({
    pressable: true,
    hoverable: true,
    hover,
    init: {
      y: 0,
      rotate: 0,
      display: 'inline-block',
      transformOrigin: 'bottom left'
    },
    press: {
      rotate: -45
    }
  }),
  // Craft
  1180: posed.div({
    pressable: true,
    hoverable: true,
    hover,
    init: {
      y: 0,
      scale: 1,
      rotate: 0,
      display: 'inline-block'
    },
    press: {
      scale: 2.5,
      rotate: 40
    }
  }),
  // Projects
  projects: posed.div({
    pressable: true,
    hoverable: true,
    hover,
    init: {
      y: 0,
      display: 'inline-block'
    },
    press: {
      y: 100
    }
  }),
  // Creatives
  99: posed.div({
    pressable: true,
    hoverable: true,
    hover,
    init: {
      y: 0,
      rotateX: 0,
      display: 'inline-block'
    },
    press: {
      rotateX: 180
    }
  }),
  // Contact
  1479: posed.div({
    pressable: true,
    hoverable: true,
    hover,
    init: {
      x: 0,
      y: 0,
      display: 'inline-block'
    },
    press: {
      x: 100,
      y: -100
    }
  }),
  default: posed.div({
    pressable: true,
    hoverable: true,
    hover,
    init: {
      y: 0,
      scale: 1,
      rotate: 0,
      display: 'inline-block'
    },
    press: {
      scale: 2.5,
      rotate: 40
    }
  })
}

export default class MenuItem extends Component {
  render() {
    const Tag =
      (this.props.item && menuItems[this.props.item.ID]) || menuItems.default
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode
    return (
      <NavLink {...this.props}>
        {isIE11 ? this.props.children : <Tag>{this.props.children}</Tag>}
      </NavLink>
    )
  }
}
