import React, { Component, Fragment } from 'react'

import { Logo, Consumer } from './Utilities'
import Menu from './Menu'

export default class Header extends Component {
  render() {
    return (
      <header className="Header">
        <Consumer>
          {({ state }) =>
            !state.loading && (
              <Fragment>
                <Logo />
                <Menu />
              </Fragment>
            )
          }
        </Consumer>
      </header>
    )
  }
}
