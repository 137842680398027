import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// import cc from 'classcat'

import { Icon } from './Utilities'

class Filter extends Component {
  render() {
    const {
      // handleFilter,
      people,
      // categories,
      // resetFilter,
      currentFilter,
    } = this.props
    // console.log(this.props)
    return (
      <div className="Filter">
        {/* <div className="Filter__category">
          <button
            className={cc({
              Filter__item: true,
              'Filter__item--is-selected': currentFilter.category === ''
            })}
            onClick={() => handleFilter({ category: '' })}
          >
            All
          </button>
          {categories.map(category => (
            <button
              key={category.id}
              className={cc({
                Filter__item: true,
                'Filter__item--is-selected':
                  currentFilter.category === category.slug
              })}
              onClick={() => handleFilter({ category: category.slug })}
            >
              {category.name}
            </button>
          ))}
        </div> */}
        {people && (
          <div className="Filter__person select">
            <select
              className="select__input"
              value={currentFilter.person}
              onChange={(e) => {
                this.props.history.push(`/work?person=${e.target.value}`)
              }}
            >
              <option value="By creator">By director</option>
              {people.map((person) => (
                <option key={person.ID} value={person.post_name}>
                  {person.post_title}
                </option>
              ))}
            </select>
            <Icon
              name="arrow-down"
              color="white"
              className="select__icon Icon--small"
            />
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(Filter)
