import React, { PureComponent } from 'react'
import cc from 'classcat'
import posed from 'react-pose'

import {
  Html,
  VimeoWrapper,
  // AspectImage,
  LinkHandler,
  Image,
} from './Utilities'

const ImageWrapper = posed.div({
  hoverable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.05,
    transition: {
      scale: {
        type: 'spring',
        stiffness: 100,
        damping: 10,
        mass: 1,
        velocity: 1,
      },
    },
  },
})

export default class ContentRenderer extends PureComponent {
  state = {
    show: false,
  }
  componentDidMount = () => {
    this.timeout = setTimeout(() => {
      this.setState({
        show: true,
      })
    }, 0)
  }
  componentWillUnmount = () => {
    clearTimeout(this.timeout)
  }
  render() {
    const { data } = this.props
    const { content } = data
    const { show } = this.state

    return (
      <div className={cc({ Content: true, 'Content--show': show })}>
        {content.type === 'image' && (
          <figure className="Content__media">
            {data.acf_fc_layout === 'full' ? (
              <div className="Content__image-wrapper">
                {/* <AspectImage
                  image={content.image}
                  className="Content__image aspect-sm--widescreen"
                /> */}
                {content.link && content.link.url ? (
                  <LinkHandler className="Content__link" url={content.link.url}>
                    <ImageWrapper>
                      <Image image={content.image} className="Content__image" />
                    </ImageWrapper>
                  </LinkHandler>
                ) : (
                  <Image image={content.image} className="Content__image" />
                )}
                <div className="Content__line" />
              </div>
            ) : (
              content.image.sizes && (
                <div className="Content__image-wrapper">
                  {content.link && content.link.url ? (
                    <LinkHandler
                      className="Content__link"
                      url={content.link.url}
                    >
                      <ImageWrapper>
                        <Image
                          image={content.image}
                          className="Content__image"
                        />
                      </ImageWrapper>
                    </LinkHandler>
                  ) : (
                    <Image image={content.image} className="Content__image" />
                  )}
                  <div className="Content__line" />
                </div>
              )
            )}
            {content.caption && (
              <figcaption className="Content__caption">
                <Html content={content.caption} />
              </figcaption>
            )}
          </figure>
        )}
        {content.type === 'text' && (
          <div
            className={cc({
              Content__text: true,
              'Content__text--to-right': content.textToRight,
            })}
          >
            <Html content={content.text} />
          </div>
        )}
        {content.type === 'video' &&
          content.video &&
          content.video.includes('vimeo') && (
            <figure className="Content__media">
              <VimeoWrapper video={content.video} autoplay={content.autoplay} />
              {content.caption && (
                <figcaption className="Content__caption">
                  <Html content={content.caption} />
                </figcaption>
              )}
            </figure>
          )}
      </div>
    )
  }
}
