import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import cc from 'classcat'
import posed from 'react-pose'

import withContext from '../../HOC/withContext'
import { pickRandom } from '../../utils/helpers'

const Graphic = posed.div({
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
})

class Logo extends Component {
  interval = setInterval(() => {
    if (!this.state.playing) {
      this.playLogos()
    }
  }, 30000)
  state = {
    currentLogo: {},
    playing: false
  }
  componentDidMount = () => {
    this.playLogos()
  }
  componentWillUnmount = () => {
    clearInterval(this.interval)
    clearTimeout(this.timeout)
  }

  playLogo = (timeout, final) => {
    this.timeout = setTimeout(() => {
      this.setState({
        currentLogo: final
          ? this.playFinal()
          : pickRandom(this.props.ctx.state.options.logo, 1)[0]
      })
    }, timeout)
  }

  playFinal = () => {
    const {
      location,
      ctx: { state }
    } = this.props

    const path = location.pathname + location.search

    const finalLogo = state.options.page_logos.filter(
      page => page.page_url.includes(path) && path !== '/'
    )

    return finalLogo.length === 0
      ? {}
      : {
          type: 'text',
          text: finalLogo[0].logo,
          image: false
        }
  }

  playLogos = (timeout = 120) => {
    this.setState({ playing: true })
    const playAmount = new Array(12).fill('x')
    playAmount.map((item, i) =>
      i < playAmount.length - 1
        ? this.playLogo(i * timeout)
        : this.playLogo(i * timeout, true)
    )
    // Reset playing state
    setTimeout(() => {
      this.setState({ playing: false })
    }, timeout * playAmount.length)
  }

  componentDidUpdate = prevProps => {
    // Update logos when route changes
    const oldPath = prevProps.location.pathname + prevProps.location.search
    const newPath = this.props.location.pathname + this.props.location.search
    if (oldPath !== newPath) {
      this.playLogos()
    }
  }

  render() {
    const { currentLogo, playing } = this.state
    return (
      <Link
        to="/"
        className="Logo"
        onMouseEnter={() => !playing && this.playLogos()}
      >
        <Graphic
          pose={currentLogo ? 'enter' : 'leave'}
          className={cc({
            Logo__graphic: true,
            'Logo__graphic--is-final': !currentLogo.hasOwnProperty('type')
          })}
        >
          {currentLogo && (
            <div className="Logo__module">
              {currentLogo.type === 'text' && (
                <p className="Logo__text">{currentLogo.text}</p>
              )}
              {currentLogo.type === 'image' && (
                <img
                  className="Logo__image"
                  src={currentLogo.image.url}
                  alt={currentLogo.image.alt || currentLogo.image.title}
                />
              )}
            </div>
          )}
          <svg
            className="Logo__line"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 5"
            preserveAspectRatio="none"
          >
            <rect width="100" height="5" x="0" y="0" />
          </svg>
        </Graphic>
        <p className="Logo__name">DEPT.</p>
      </Link>
    )
  }
}

export default withRouter(withContext()(Logo))
