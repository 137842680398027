import React, { Component } from 'react'
import posed from 'react-pose'

import { removeTrailingSlash } from '../utils/helpers'

import { Consumer, Icon } from './Utilities'
import SideNav from './SideNav'
import MenuItem from './MenuItem'

const Trigger = posed.button({
  hoverable: true,
})
const TriggerIcon = posed.div({
  init: { rotate: '0deg', display: 'inline-block' },
  hover: { rotate: '90deg', display: 'inline-block' },
})

export default class Menu extends Component {
  // Check if active because of query paramters in the custom menu points (animation, film, things)
  checkIfActive = (item, match, location) => {
    if (location.search) {
      return item.url === `${location.pathname}${location.search}`
    } else {
      return match
    }
  }
  render() {
    return (
      <Consumer>
        {(ctx) => (
          <div className="Menu">
            <nav className="Menu__wrapper">
              {/* {ctx.state.menus.primary &&
                ctx.state.menus.primary.items.map((item, i) => (
                  <MenuItem
                    key={item.ID}
                    item={item}
                    to={removeTrailingSlash(item.url)}
                    className="Menu__item"
                    activeClassName="Menu__item--is-active"
                    isActive={(match, location) =>
                      this.checkIfActive(item, match, location)
                    }
                  >
                    {item.title}
                  </MenuItem>
                ))} */}
              <div className="Menu__secondary">
                {ctx.state.menus.secondary &&
                  ctx.state.menus.secondary.items.map((item, i) => (
                    <MenuItem
                      key={item.ID}
                      item={item}
                      to={removeTrailingSlash(item.url)}
                      className="Menu__item"
                      activeClassName="Menu__item--is-active"
                      isActive={(match, location) =>
                        this.checkIfActive(item, match, location)
                      }
                    >
                      {item.title}
                    </MenuItem>
                  ))}
              </div>
            </nav>
            <div className="Menu__mobile">
              <Trigger
                className="Menu__trigger"
                onClick={ctx.actions.toggleMenu}
              >
                <span className="Menu__trigger-text">Menu</span>
                <TriggerIcon>
                  <Icon
                    name="menu"
                    className="Menu__trigger-icon"
                    color={ctx.state.darkTheme ? 'white' : 'black'}
                  />
                </TriggerIcon>
              </Trigger>
              <SideNav checkIfActive={this.checkIfActive} />
            </div>
          </div>
        )}
      </Consumer>
    )
  }
}
