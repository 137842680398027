import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Icon from './Icon'
import { removeTrailingSlash } from '../../utils/helpers'
import posed from 'react-pose'

/**
 * Handles external and internal links
 *
 * Usage:
 *
 *    <LinkHandlerButton
 *      className="button"
 *      link={section.button.link}
 *      title={section.button.title}
 *    />
 */

const Button = posed.div({
  hoverable: true
})

const ButtonText = posed.span({
  init: {
    x: 0
  },
  hover: {
    x: -50
  }
})

const ButtonIcon = posed.span({
  init: {
    x: 0
  },
  hover: {
    x: 50
  }
})

export default class LinkHandler extends Component {
  render() {
    const { url, title, className, withIcon } = this.props
    return url[0] === '/' || url.includes('dept.no') ? (
      <Link className={className && className} to={removeTrailingSlash(url)}>
        <Button className="button__inner">
          <ButtonText style={{ display: 'inline-block' }}>{title}</ButtonText>
          {withIcon && (
            <ButtonIcon style={{ display: 'inline-block' }}>
              <Icon className="button__icon" name="arrow-right" />
            </ButtonIcon>
          )}
        </Button>
      </Link>
    ) : (
      <a
        className={className}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {title}
        {withIcon && <Icon className="button__icon" name="arrow-right" />}
      </a>
    )
  }
}
