import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { Consumer, Html } from './Utilities'
import Social from './Social'

export default class Footer extends Component {
  render() {
    return (
      <Consumer>
        {({ state }) =>
          !state.loading && (
            <footer className="Footer">
              <div className="Footer__container">
                {state.options.contact && (
                  <div className="Footer__contact">
                    {state.options.contact.email && (
                      <p className="Footer__item">
                        <a href={`mailto:${state.options.contact.email}`}>
                          {state.options.contact.email}
                        </a>
                      </p>
                    )}
                    {state.options.contact.phone && (
                      <p className="Footer__item">
                        <a href={`tel:${state.options.contact.phone}`}>
                          {state.options.contact.phone}
                        </a>
                      </p>
                    )}
                    {state.options.contact.adress && (
                      <div className="Footer__item">
                        <Html content={state.options.contact.adress} />
                      </div>
                    )}
                  </div>
                )}
                <div className="Footer__privacy">
                  <Link to="/privacy">Privacy policy</Link>
                </div>
                <section className="Footer__some">
                  <Social />
                </section>
              </div>
            </footer>
          )
        }
      </Consumer>
    )
  }
}
